//dev ext
/* export const BASE_URL = "http://porthosulprodutora.com.br:3737";
export const APP_URL = "http://192.168.1.7";
export const URL_RETORNO = "http://sistema.estudioformando.com.br"; */

//dev loc
/* export const BASE_URL = "http://192.168.0.252:3737";
export const APP_URL = "http://192.168.0.176";
export const URL_RETORNO = "http://sistema.estudioformando.com.br"; */

//prod
export const BASE_URL = "https://e4producoes.com.br:3737";
export const APP_URL = "https://e4producoes.com.br";
export const APP_MASK = "https://e4producoes.com.br";
export const URL_RETORNO = "https://e4producoes.com.br";

export const BASE_URL_BANK = "https://mpag.bb.com.br/site/mpag/";
export const ALBUM_LIMIT = 50;
export const FILTER_FORMATURA = 199;
export const TAXA_BOLETO = 3.5;
/* export const ID_CONV = "325350";
export const REF_TRAN = "3515645"; */
export const ID_CONV = "326270";
export const REF_TRAN = "3007181";
export const TP_PAGAMENTO = "2";
export const TP_VIA_2 = "21";
export const INDICADOR_PESSOA = "1";
export const TP_DUPLICATA = "DM";

export const SEDEX_PADRAO = 30;

export const FLAG_CONVIDADO_ID = 111;

export const PARCELA_MINIMA = 50;
export const MAX_PARCELAS = 12;
export const MAX_SEM_JUROS = 1;
export const EXIBE_UP_COMPLEMENTAR = 1;

/* export const CHAVE_PIX = "31779884000158";
export const TITULAR_PIX = "Estudio Formando Eireli"; */
export const CHAVE_PIX = "05018226000133";
export const TITULAR_PIX = "Tri Producoes";
export const CIDADE_PIX = "Porto Alegre";
export const EMAIL_FINANCEIRO = "financeiro@estudio4producoes.com.br";
export const WHATSAPP_FINANCEIRO = "(51) 99315-4438";

export const POLITICA_DE_PRIVACIDADE = `
 SEÇÃO 1 – INFORMAÇÕES GERAIS
A presente Política de Privacidade contém informações sobre coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos usuários, com a finalidade de demonstrar absoluta transparência quanto ao assunto e esclarecer a todos interessados sobre os tipos de dados que são coletados, os motivos da coleta e a forma como os usuários podem gerenciar ou excluir as suas informações pessoais.
Esta Política de Privacidade aplica-se a todos os usuários do Portal Estúdio 4 Produções, empresa devidamente inscrita no CNPJ sob o nº 05.018.226/0001-33 situada na Rua Sérgio Jungblut Dietrich, 604 - prédio 4 - Porto Alegre - RS, doravante nominada Estúdio 4 produções.
O presente documento foi elaborado em conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18), o Marco Civil da Internet (Lei 12.965/14) (e o Regulamento da UE n. 2016/6790). Ainda, o documento poderá ser atualizado em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção.
SEÇÃO 2 – COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO?
Os dados pessoais do usuário são recolhidos pela plataforma da seguinte forma:
•	Quando o usuário cria uma conta/perfil no Portal www.e4producoes.com.br , nós precisamos de informações básicas do usuário para identificação do acesso. Essas informações envolvem e-mail ou um campo de usuário e uma senha que será guardada usando um método de criptografia do tipo hash em nossas bases de dados.
•	Quando um usuário compra um ou mais produtos em nosso site, salvamos as informações que o usuário nos fornece para geração de pedido e envio do produto. Neste caso, todas as informações fornecidas ficarão salvas para futuras compras.
•	Quando acessa páginas do nosso portal as informações sobre interação e acesso são coletadas pela empresa para garantir uma melhor experiência ao usuário. Estes dados podem tratar sobre as palavras-chaves utilizadas em uma busca, o compartilhamento de um documento específico, comentários, visualizações de páginas, perfis, a URL de onde o usuário provém, o navegador que utilizam e seus IPs de acesso, dentre outras que poderão ser armazenadas e retidas.
•	Por intermédio de terceiros, usamos scripts de estatísticas criados por empresas de terceiros, como Facebook e Google, para coletar dados sobre páginas e produtos acessados, adicionados ao carrinho de compras, finalizações de compra inicializadas e compras realizadas em nosso site. Além disso, também coletamos informações demográficas e dados do dispositivo usado para acesso ao nosso site.
SEÇÃO 3 – QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO?
Os dados pessoais do usuário recolhidos são os seguintes:
•	Dados para a criação da conta/perfil: como e-mail ou usuário, nome completo e senha.
•	Dados para otimização da navegação: como acesso a páginas, palavras-chave utilizadas na busca, recomendações, comentários e endereço de IP.
•	Dados para concretizar transações: dados referentes ao endereço físico para entrega de compras, além dos pagamentos efetuados.
•	Newsletter: o e-mail cadastrado pelo usuário.
•	Dados relacionados a contratos: diante da formalização do contrato de prestação de serviços entre o Estúdio Formando e o usuário poderão ser coletados e armazenados dados relativos à execução contratual, inclusive as comunicações realizadas entre a empresa e o usuário.
•	Todas as comunicações entre o Estúdio Formando e o usuário.
SEÇÃO 3 – PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO USUÁRIO?
Os dados pessoais do usuário coletados e armazenados pelo Estúdio 4  tem por finalidade:
•	Bem-estar do usuário: aprimorar o produto e/ou serviço oferecido, facilitar, agilizar e cumprir os compromissos estabelecidos entre o usuário e a empresa, melhorar a experiência dos usuários e fornecer funcionalidades específicas a depender das características básicas do usuário.
•	Melhorias da plataforma: compreender como o usuário utiliza os serviços da plataforma, para ajudar no desenvolvimento de negócios e técnicas.
•	Anúncios: apresentar anúncios personalizados para o usuário com base nos dados fornecidos.
•	Comercial: os dados são usados para personalizar o conteúdo oferecido e gerar subsídio à plataforma para a melhora da qualidade no funcionamento dos serviços.
•	Previsão do perfil do usuário: tratamento automatizados de dados pessoais para avaliar o uso na plataforma.
•	Dados de cadastro: para permitir o acesso do usuário aos conteúdos da plataforma.
•	Dados de contrato: conferir às partes segurança jurídica e facilitar a conclusão do negócio.
O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá mediante comunicação prévia ao usuário, de modo que os direitos e obrigações aqui previstos permanecem aplicáveis.
SEÇÃO 4 – POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?
Os dados pessoais do usuário são armazenados pela plataforma durante o período necessário para a prestação do serviço ou o cumprimento das finalidades previstas no presente documento, conforme o disposto no inciso I do artigo 15 da Lei 13.709/18.
Os dados podem ser removidos ou anonimizados a pedido do usuário, excetuando os casos em que a lei oferecer outro tratamento.
Ainda, os dados pessoais dos usuários apenas podem ser conservados após o término de seu tratamento nas seguintes hipóteses previstas no artigo 16 da referida lei:
I – cumprimento de obrigação legal ou regulatória pelo controlador;
II – estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;
III – transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei;
IV – uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.
SEÇÃO 5 – SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS
A plataforma se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.
A plataforma não se exime de responsabilidade por culpa exclusiva de terceiros, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiros. O site se compromete a comunicar o usuário em caso de alguma violação de segurança dos seus dados pessoais.
Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites legais. No entanto, podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo ou se você violar nossos Termos de Serviço.
SEÇÃO 6 – COMPARTILHAMENTO DOS DADOS
O compartilhamento de dados do usuário ocorre apenas com os dados referentes a publicações realizadas pelo próprio usuário, tais ações são compartilhadas publicamente com os outros usuários.
Os dados do perfil do usuário são compartilhados apenas dentro da plataforma, não incorrendo nos resultados de busca de ferramentas de pesquisa da web.
SEÇÃO 6 – OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A TERCEIROS?
O Estúdio Formando não compartilha dados pessoais dos clientes com terceiros sem autorização prévia. Salvo em casos onde a lei prevalece.
SEÇÃO 07 – COOKIES OU DADOS DE NAVEGAÇÃO
Os cookies referem-se a arquivos de texto enviados pela plataforma ao computador do usuário e que nele ficam armazenados, com informações relacionadas à navegação no site. Tais informações são relacionadas aos dados de acesso como local e horário de acesso e são armazenadas pelo navegador do usuário para que o servidor da plataforma possa lê-las posteriormente a fim de personalizar os serviços da plataforma.
O usuário do Portal do Formando manifesta conhecer e aceitar que pode ser utilizado um sistema de coleta de dados de navegação mediante à utilização de cookies.
O cookie persistente permanece no disco rígido do usuário depois que o navegador é fechado e será usado pelo navegador em visitas subsequentes ao site. Os cookies persistentes podem ser removidos seguindo as instruções do seu navegador. Já o cookie de sessão é temporário e desaparece depois que o navegador é fechado. É possível redefinir seu navegador da web para recusar todos os cookies, porém alguns recursos da plataforma podem não funcionar corretamente se a capacidade de aceitar cookies estiver desabilitada.
SEÇÃO 8 – CONSENTIMENTO
Ao utilizar os serviços e fornecer as informações pessoais na plataforma, o usuário está consentindo com a presente Política de Privacidade.
O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus direitos de cancelar seu cadastro, acessar e atualizar seus dados pessoais e garante a veracidade das informações por ele disponibilizadas.
O usuário tem direito de retirar o seu consentimento a qualquer tempo, para tanto deve entrar em contato com o Estúdio Formando para tal.
SEÇÃO 9 – ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE
Reservamos o direito de modificar essa Política de Privacidade a qualquer momento, então, é recomendável que o usuário a revise com frequência.
As alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação na plataforma. Quando realizadas alterações os usuários serão notificados. Ao utilizar o serviço ou fornecer informações pessoais após eventuais modificações, o usuário demonstra sua concordância com as novas normas.
Diante da fusão ou venda da plataforma à outra empresa os dados dos usuários podem ser transferidos para os novos proprietários para que a permanência dos serviços oferecidos.
SEÇÃO 10 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS
Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro.
Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede da empresa.

CONTRATO DE FORMATURA
Firmam entre si, a contratada VIP PRODUTORA LTDA, estabelecida à rua Sérgio Jungblut Dietrich, 604 prédios 4, na cidade de Porto Alegre – RS, neste ato representada por Estúdio Formando inscrita no CNPJ 05.018.226/0001-33, e de outro lado, denominado formando aqui cadastrado.
Pelo presente instrumento particular de serviço, e na melhor forma de direito, as partes supracitadas, tem por justo e acertado o presente contrato, que reger-se-á pelas cláusulas e condições que seguem:
1. VÍDEO: Filmagem da formatura composta por filmadoras digitais FULL HD e ilha de edição de vídeo ao vivo para transmissão das imagens geradas para os telões de retorno de imagens, conforme descritos no quadro 1 de serviços contratados.
A contratada compromete-se a manter por 120 dias, os vídeos aos formandos (as) contratantes nos links informados. Após esta data terá uma taxa de regravação de R$ 200,00 (Duzentos Reais) por vídeo solicitado.
O formando(a) que não participar da prova de togas individual nos Estúdios da Produtora, terá exibido somente imagens ao vivo da colação de grau.
A contratada compromete-se a manter os vídeos em seus arquivos, pelo período de até 06 (meses) após a data da formatura.
1.2 - TEASER: A contratada compromete-se a disponibilizar o link do compacto de imagens aleatórias da formatura (Teaser), no portal do
formando (a), no prazo de 90 dias úteis após a formatura, permanecendo em armazenamento por 06 meses após será cobrado uma taxa de R$ R$200,00 
2. FOTOGRAFIAS: Cobertura fotográfica da formatura com fotógrafos com câmeras FULL FRAME e demais itens descritos no quadro 02 de serviços contratados. Cada formando (a) poderá fazer o download de suas fotos (30 dias uteis após cada evento, prova de togas e formatura), em alta resolução e sem a logo da produtora, após o pagamento de 50 % do valor do contrato para fotos dos eventos e prova de togas e de 100 % do valor do contrato para as fotos da formatura.
As fotos da prova de togas e formatura ficarão disponibilizadas no site: www.e4producoes.com.br   por 120 dias corridos após a data da formatura. Após este prazo o formando (a) poderá se dirigir ao escritório da produtora e adquirir as fotos pelo valor praticado pelo mercado na época da compra.
2.1 - Cada formando (a) deverá agendar sua prova de togas individual, nos estúdios da Produtora, de segunda a sexta-feira nos horários das 09:00 as 12:00 e das 14:00 as 17:00 horas, com até 5 familiares, agendamento deve ser feito via link: www.agendabookdoformando.zohobookings.com  A prova de togas terá duração de até 30 minutos e serão produzidas 30 a 40 fotos que serão disponibilizadas no portal do formando (a).  Para participação de mais de 5 familiares na Prova de Togas individual no Estúdio da Produtora, mediante custo extra de R$ 50,00 por acompanhante que exceder a quantidade de 5 acompanhantes.
2.2 – O formando (a) que não realizar a prova de togas individual nos Estúdios da Produtora, não terá sua foto para o convite virtual, para seu quadro e para exibição no telão de Leds no momento da colação de grau, nada tendo a reclamar.
2.3 – O formando (a) que não realizou a prova de togas individual no Estúdio da Produtora, poderá escolher uma foto do dia da formatura para seu quadro após a realização da formatura e agendar sua retirada na produtora.
2.4 – O agendamento da prova de togas individual, deverá ser realizado com no mínimo 120 dias de antecedência a data de sua formatura, através do WhatsApp (51) 99146-5094, em horário comercial.

2.5 Sábados no estúdio da produtora são reservados somente para Book Fotográficos. Caso deseje agendar a prova de togas em sábado no estúdio da produtora, mediante a taxa de R$ 250,00.  Agendamento somente mediante a taxa de R$250,00 sessão de 30 minutos com até 05 familiares.
2.6 - A contratada compromete-se a disponibilizar ao contratante, fotógrafos profissionais com câmeras digitais FULL FRAME, para cobertura dos eventos, conforme descritos no quadro 2.
2.7 – Fotos impressas dos eventos e formatura não fazem parte do presente contrato. Para aquisição de fotos impressas, através do site portalestudioformando.com.br, ou diretamente na sede da contratada, ao custo de R$ 25,00 (Vinte e cinco Reais) cada foto, no tamanho 15 por 21 cm, valor que deverá ser pago no ato do pedido. O valor poderá ser pago via depósito bancário, PIX, ou diretamente na sede da contratada. Para fotos de outros tamanhos mediante consulta de valor.
As fotos adquiridas estarão à disposição para retirada, em 20 (Vinte) dias úteis após confirmação via fone 51 2160-3799 ou e-mail comercial@estudio4producoes.com.br , diretamente na sede da contratada conforme endereço, dias e horários citados no presente contrato. A sessão de fotos para qualquer material que necessite de fotos exemplo: convites, quadro, será realizada no dia da prova de togas individual no estúdio da Produtora.
As imagens são exclusivas da contratada, e não serão cedidas em cortesia para outros fins que não sejam acordados com a contratada.
As fotos adquiridas permanecerão a disposição do formando (a) pelo período de 120 (Cento e Vinte) dias corridos a partir da data do pagamento mediante o envio do comprovante a contratada. Após este prazo o material será descartado e o formando (a) terá que realizar nova escolha diretamente na sede da contratada, mediante novo pagamento das fotos pelo valor praticado pelo mercado no ato da nova aquisição. 2.10 - A contratada compromete-se a manter as fotos no portal www.e4producoes.com.br  pelo período de 120 (Cento e Vinte) dias corridos após a formatura. Após este prazo somente no escritório da contratada, mediante taxas.
2.08 - Não será permitido pela contratada, em hipótese alguma, a presença de fotógrafos particulares na área de trabalho dos profissionais da contratada durante a prova de togas e cerimônia de colação de grau, bem como a área Vip reservada aos familiares.
2.09 - Não será aceito pela produtora, fotos para convite virtual, quadro e telão de Leds, que não sejam produzidas pela contratada.
2.10 - Ficam cientes os contratantes que, em se tratando de uma cerimônia de formatura onde o protocolo é rápido e dinâmico e o registro se aproxima de uma cobertura fotográfica, tipo foto jornalística, a produtora não pode garantir todas as imagens iguais e repetidas de todos os formandos (as), podendo haver variação de captação ou mesmo até não ocorrer a captação de uma determinada imagem durante toda a cerimônia e ou coberturas.
2.11 - A responsabilidade de fotografar com seus familiares e amigos é de cada formando (a). Os mesmos deverão se dirigir aos estúdios da contratada ou aos demais fotógrafos de bastidores devidamente identificados.
2.13 - A contratada compromete-se a manter as fotos em seus arquivos, pelo período de até 120 (dias) após a data da realização do evento, A contratada não tem responsabilidade em armazenamento após a data aqui pactuada.
3. FOTO CONVITE DIGITAL: Caso conste em seu contrato no quadro 2 itens contratados, será confeccionada com uma arte única da produtora com os dados do cadastro do formando em caso de alteração será cobrado uma taxa de R$ 30,00 para alteração.
Para aquisição de foto convite impressa no tamanho 14x20, mediante o custo extra de R$ 20,00 (Vinte Reais) cada foto convite impressa. A foto convite impressa estará à disposição (as) no escritório da contratada, 30 dias úteis após a aprovação e pagamento.
4. CAMISETAS: Caso conste em seu contrato no quadro 8 de itens contratados, camiseta com uma arte por curso, artes disponibilizadas pela Produtora.
4.1 - As camisetas serão entregues aos formandos (as) no dia da prova de togas individual no Estúdio da Produtora, o formando deverá estar com 50% dos valores quitado do seu pacote com até 15 dias de antecedência o agendamento, camiseta será entrega no dia do ensaio de fotos, caso não esteja pronta o formando pode retirar no dia do ensaio de formatura.
5. COQUETEL: A Contratada disponibiliza aos formandos e professores no dia de cerimônia de formatura, coquetel com salgadinhos, refrigerantes e água. 
6. HOMENAGEM DE CLIPE DE FOTOS: Para montagem do clipe de HOMENAGEM, o contratante compromete-se a postar em seu portal e4producoes.com.br   sugestão ( 01 foto de infância, 01 foto de adulto e 01 foto de família) (ou 3 fotos de sua preferência), com 90 (noventa  dias corridos de antecedência a data da formatura.
6.1 - O formando que não obedecer ao prazo supracitado, deverá entregar o seu material devidamente identificado, diretamente na sede da contratada, com no mínimo 45 (quarenta e cinco) dias corridos de antecedência a data da formatura, conforme endereço, dias e horários de funcionamento do escritório da contratada, citados no presente contrato, juntamente com a taxa de R$ 30,00.
Após este prazo o formando não terá exibido suas fotos no clipe, nada tendo a reclamar.
7. TOGAS: Toga completa para a prova de togas e colação de grau (toga, faixa, sobre faixa, jabô e barrete), para todos os formandos e componentes da mesa de autoridades, confeccionadas em microfibra.
7.1 - A toga será entregue no dia da formatura a cada formando individualmente, mediante apresentação de documento de identificação.
7.2 - A não devolução da toga pelo formando no prazo máximo de 02 horas após o encerramento da solenidade, implicará em custo de R$ 250,00 (Duzentos e Cinquenta Reais) por dia, em forma de aluguel da toga.
7.3 - A contratada disponibilizará equipe para vestir e receber de volta as togas dos formandos nos dias da prova de togas e da formatura.  - A contratada não tem responsabilidade alguma sobre pertences dos formandos nos dias da prova de togas e da formatura.
8. ILUMINAÇÃO CÊNICA: A iluminação cênica é projetada de acordo com os cursos e instalada no palco compondo com a decoração.
A definição da iluminação e como deverá ser instalada é de responsabilidade dos técnicos da contratada de acordo com a decoração disponibilizada.
9. SONORIZAÇÃO: Sonorização completa da formatura com música de composição da mesa de autoridades, do paraninfo, de entrada da turma, individual e de encerramento. Microfone com e sem fio para mesa, púlpito e reitor.
9.1 - O formando deverá postar sua música em MP3, com tempo mínimo de 1,30 minutos editada no ponto certo.
Desde já os formandos ficam cientes que a produtora não faz corte nem mixa a música, caso o formando necessite deste serviço de corte e mixar ao custo de R$ 30,00.
9.2 - A produtora não tem responsabilidade caso a música escolhida não toque na formatura por erro, censura ou postagem de forma corrompida.
9.3 - A música postada é de total responsabilidade de cada formando, portanto certificar-se que sua música está correta.
A música deverá ser postada diretamente no portal www.e4producoes.com.br com 60 (sessenta) dias de antecedência a data da formatura.
O formando que não obedecer ao prazo supracitado, entrará na formatura com a música escolhida pela produtora, sem nada a reclamar.
10. DECORAÇÃO: Decoração inovadora possibilitando a personalização com luzes, promovendo um verdadeiro espetáculo visual de acordo com a iluminação projetada, composta pelos itens disponibilizados no quadro 6 de itens.
11. HOMENAGENS:
Aos professores homenageados pela turma, a contratada disponibiliza conforme quadro 7 de itens contratados. Aos Familiares, a contratada disponibiliza conforme quadro 7 de itens contratados.
12. DEMAIS SERVIÇOS: Para outros serviços e/ou eventos, mediante consulta de valores para grupos de no mínimo 30 formandos (as) ex.: Apitaço, Prova de Togas coletivas, Festa dos 100 dias, Churrasco.
13. Os serviços disponibilizados pela Produtora são os descritos nos quadros de itens contratados abaixo, de 1 a 8.
QUADRO 01 – VIDEOS:
Vídeo da formatura disponibilizado pelo Link da Transmissão ao vivo pelo canal do Youtube da Produtora. (Opcional) Ilha de edição FULL HD com editor de vídeo para geração e transmissão ao vivo das imagens para os telões.
Câmeras FULL HD e cinegrafistas na formatura.
Telão de LEDS na formatura, (Caso conste no contrato)
Telões de projeção de alta definição para retorno de imagens aos convidados.
Monitor de TV para retorno de imagens aos formandos e mesa de autoridades.
Teaser - vídeo compacto dos Melhores momentos da formatura até 90 dias úteis após a formatura. (Caso conste em seu contrato)
QUADRO 02 - FOTOGRAFIAS:
Cobertura fotográfica da formatura com fotógrafos com câmeras FULL FRAME.
Estúdios temáticos com flashes e luz rebatedora na formatura.
Fotos livres de marca d’água e logo da produtora de forma digital para baixar no portal da produtora www.e4producoes.com.br
Clipe de fotos dos formandos, infância, adulto, família ou a livre escolha, postada diretamente portalestudioformando.com.br por cada formando nos prazos estipulado em seu contrato.
QUADRO 03 - ESTRUTURA DE TOGAS:
- 01 Toga completa por formando (a) Para a Prova de togas e formatura (locada-devolvida ao final da prova de togas e formatura
- Togas para os professores componentes da mesa. 
- Equipe de suporte no camarim para vestir formandos e professores e fazer os ajustes necessários. 
- Araras e biombos para os formandos guardarem suas roupas. (Caso conste em sua proposta)
QUADRO 04 -ESTRUTURA DE SONORIZAÇÃO:
- Sonorização completa do auditório ou local da formatura.
- 01 Música por formando postada por cada formando 
- Música de entrada definida pela Produtora, entrada única para todos os cursos. 
- Música encerramento definida pela produtora.
- Conferência das músicas no ensaio de formatura.
- Taxa ECAD por conta da Produtora LEI 9.610/98. (Caso conste em sua proposta)
QUADRO 05 - ESTRUTURA DE ILUMINAÇÃO E EFEITOS:
- Iluminação cenográfica completa do palco da formatura. 
- Efeitos especiais com Been Lead  na abertura da formatura, homenagem aos pais/professores e encerramento da formatura.
- Técnico de iluminação e auxiliar.
- Iluminação branca para foto e vídeo.
- Canhões de LED coloridos para cenário.
- Black out com Show de Luzes, Laser e Fumaça para abertura e encerramento da formatura
QUADRO 06 - ESTRUTURA DE CENÁRIO PROPOSTA (DECORAÇÃO):
- Arranjo de flores na mesa de colação e púlpito.
- Cadeiras de acrílico Tiffany transparente para os formandos e mesa de colação iluminadas com LEDS colorido. 
- Plantas em pedestais para compor o cenário.
- Montagem de praticável para os formandos. (Caso conste em sua proposta)
- Mesa de vidro para autoridades e colação de grau.
QUADRO 07 - MOMENTO HOMENAGENS:
- Para cada 50 formandos (as), 01 Placa de homenagem no tamanho 15 x 21 em aço escovado e veludo, 
- Vídeo de Homenagem aos pais com clipe de fotos ou vídeo genérico da Produtora.
QUADRO 08 - ITENS EXTRAS:
- Contratação de Cerimonialista para a formatura. (Caso conste em sua proposta)
- 01 Porta diplomas na cor do curso, por formando – canudo. (Caso conste em sua proposta)
- 01 Foto convite Digital por formando (a), (Conforme condições do item 3 do presente contrato). (Caso conste em sua proposta)
- 01 Camiseta por formando com a arte do curso na Prova de Togas Individual no estúdio. (Caso conste em sua proposta)
- 01 quadro de parede por formando (a), com foto 30 x 40 cm. (Caso conste em sua proposta)
- 02 Rosa para homenagem aos pais. (Caso conste em sua proposta)
- 10 acessos de familiares para formatura, por formando (a), acesso extra ao custo de R$ 25,00 cada. (Pulseiras) (Caso conste em sua proposta)
- Coquetel de salgadinhos, água e refrigerantes, servido aos formandos e professores antes da formatura - cortesia da produtora.
AUTORIZAÇÃO DE IMAGEM:
As imagens captadas em qualquer evento pertinente ao presente contrato, são de exclusividade da contratada, e poderão ser utilizadas para divulgação de marketing interno ou externo, nos termos da lei de direitos autorais.
CONDICÕES:
1. Valores, cada contrato tem seu valor, bem como seu local para realização da cerimônia de formatura, cada formando tem esta informação
em seu portal aba contratos, demais regras permanece em seu portal.
2. A Contratada é responsável na captação de formandos (as).
3. Contrato individual, cada formando (a) é responsável pelo pagamento de seu contrato.
4. Pagamento nos cartões de crédito, direto no portal e4producoes.com.br
5. O valor total de cada formando deverá estar quitado até 60 dias de antecedência a data da cerimônia de formatura.
6. Para agendar a da prova de togas o valor de no mínimo 50% do contrato deverá estar quitado.
7. Os arquivos digitais dos eventos e da formatura são de propriedade exclusiva da contratada, conforme lei federal no 9.610 de 19 de
fevereiro de 1988, não sendo os mesmos cedidos ou vendidos aos formandos.
8. A cobertura de vídeo ou fotográfica, do baile de formatura, não fazem parte do presente contrato. Estes serviços deverão ser contratados
separadamente por cada formando (a) ou em grupos.
9. Caso o formando (a) desista da formatura, não terá direito a ressarcimento total ou parcial do valor já pago à contratada.
10. A contratada não se responsabiliza por danos causados pelos frequentadores dos eventos.
11. Caso o formando (a) assine o contrato e não realize a Prova de Togas individual nos Estúdios da Produtora, não terá credito e ou
ressarcimento pela não realização.
12. O book nos estúdios da produtora, NÃO faz parte do presente contrato. O formando (a) interessado neste serviço poderá escolher o pacote de sua conveniência pela loja virtual: lojaestudio4.com.br 
13. Desde já fica definido que poderão compor a mesa de autoridades, além do presidente de mesa mais 3 paraninfos que representarão todos os cursos da solenidade.
14. Os demais professores homenageados não farão parte da mesa de autoridades, ficando os mesmos na primeira fileira de cadeiras do público, no lado em que estiver montada a mesa diretiva.
15. No caso de cursos com número inferior a 15 formandos (as) os mesmos serão representados por 01 paraninfo dos outros cursos de acordo com a área de formação.
16. Desde já fica estabelecido que os discursos dos oradores não poderão ultrapassar o tempo de 3 minutos. Os prováveis oradores deverão se organizar para este momento, no dia do ensaio de formatura, através de votação entre os formandos (as) presentes.
17. Desde já fica estabelecido somente 01 juramentista que representará todos os cursos da formatura. Os prováveis interessados deverão se organizar para este momento, no dia do ensaio de formatura, através de votação entre os formandos (as) presentes.
18. Desde já fica estabelecido que os discursos dos Paraninfos não poderão ultrapassar o tempo de 2 minutos por paraninfo.
19. O ensaio de formatura quando se aplicar, ocorrerá no auditório da produtora, agenda se dará via portal 
informação da data e horário sendo postada no portal dos formandos (as) com até 30 dias corridos de antecedência a data da formatura.
20. Não serão colocados no site da contratada ou entregues fotos quadros convites e outros produtos ao formando (a) que possuir algum
débito junto à contratada, até que o mesmo seja quitado em moeda corrente da data da quitação.
21. A contratada não tem responsabilidade alguma por casos fortuitos e de força maior (ex. Blackout no bairro, Vendavais, Pandemias, entre
outros).
22. A produtora poderá alterar data, local e horário da formatura, mediante comunicação aos formandos via portal do Formando (a) com o
prazo mínimo de 30 dias corridos de antecedência a data da formatura.
23. A contratada compromete-se a manter em seus arquivos de vídeo e fotografia, pelo período de 120 dias corridos após a formatura.
Após este prazo a contratada não terá mais compromisso com o armazenamento.
24. A produtora não aceita fotos de terceiros para quadros convites e ou algum outro serviço, ficam cientes os formandos (as) que serão
aceitos somente fotos e vídeos captados pela Produtora.
25. Produtos adquiridos e pagos permanecerão a disposição do formando pelo período de 60 (sessenta dias úteis) a partir da data do pagamento e envio do comprovante a contratada via WhatsApp 51 99315-4438 ou e-mail financeiro@estudio4producoes.com.br (com seu nome CPF e assunto). Após este prazo o material será descartado e o formando terá que realizar nova compra diretamente na sede da contratada, mediante novo pagamento das fotos pelo valor praticado pelo mercado no ato da nova aquisição.
26. O contrato somente terá validade após pagamento integral, caso o formando assine o contrato e não realize nenhum pagamento pelo prazo de 30 dias após assinatura do presente contrato o mesmo será nulo. O formando não terá direito a reclamações posteriores a 30 dias do seu cadastro no portal.
27. A partir do cadastro via link enviado com data e local e todos os itens incluso para cerimônia de formatura, e seu aceite neste presente contrato online, perdem valor todas as negociações realizadas anteriormente, passando a ter valor somente os itens em seu portal.
28. A partir de seu auto cadastro, você deverá dividir o valor total de sua formatura que consta em seu portal pelos meses que faltam até a formatura, e iniciar seus pagamentos para que tudo transcorra dentro da normalidade.
 Em caso do NÃO pagamento da primeira parcela em até 30 dias corridos após sua adesão a formatura, seu cadastro será excluído do portal e a vaga liberada a outro formando (a) na sua data de formatura, pois as vagas são limitadas para cada data.  Para pagamentos via CARTÃO DE CRÉDITO em até 4 vezes através de seu portal, você NÃO precisa enviar o comprovante a Produtora, o valor baixará automaticamente em seu portal, em até 5 dias úteis. Para pagamentos via BOLETO bancário através de seu portal, você NÃO precisa enviar o comprovante a Produtora, o valor baixará automaticamente em seu portal, em até 5 dias úteis. 5. Para pagamentos via PIX através de seu portal, você NÃO precisa enviar o comprovante a Produtora, o valor baixará automaticamente em seu portal, em até 5 dias úteis, (saldo se o pix for por terceiros, neste caso deve enviar comprovante para WhatsApp 51 99315-4438 juntamente com seu cpf)
29. Este contrato tem validade para formaturas que acontecerão a partir de 2024 os demais perdem a validade.
30. Não é valido contrato enviado via PDF e-mail e ou outros meios de comunicação.
31. Cancelamento de itens ou do contrato (em até 7 dias corridos após a assinatura e pagamento), sendo que a produtora se reserva o direito  de realizar a devolução do valor em até 60 dias uteis após o cancelamento mediante agendamento. O formando (a) não terá direito a ressarcimento parcial ou total pago a produtora após 7 dias da assinatura do presente contrato, caso o formando (a) tenha pago por produtos ou serviços e por força maior queira cancelar seu produto, o mesmo poderá trocar os valores por produtos em nossa loja virtual, descontando taxas administrativa e multas. A produtora não trabalha com devolução de valores.
32. As partes de comum acordo elegem o Fórum Central de Porto Alegres, para dirimir quaisquer dúvidas oriundas do presente contrato, por mais privilegiado que outro seja. E por estarem justos e acordados, firmam o presente contrato.

`;
